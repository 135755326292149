import React from "react";
import { Carousel } from "flowbite-react";
import carousel from "../assets/imagesgallery/img1.jpeg";


export default function Presidents() {
  return (
    <>
      <div className="flex bg-black justify-center items-center py-4">
        <h1 className="text-green-100 myfont text-3xl lg:text-7xl sm:text-6xl font-semibold">
          OUR LEADERS
        </h1>
      </div>
      <div className="h-[100vh] sm:h-[100vh] myfont   md:h-[100vh]  xl:h-[90vh] 2xl:h-[100vh] relative">
        <Carousel className="">
          {[carousel].map((src, index) => (
            <div className="relative w-full h-full" key={index}>
              <img
                src={src}
                className="w-full bg-slate-900 brightness-50 h-full object-cover"
              />
              <div className="absolute flex-col myfont flex left-0 lg:bottom-40 bottom-20 lg:h-20 md:h-10 h-5 gap-0 min-w-full bg-opacity-50 text-center">
                <p className="text-white myfont oxygen-bold text-2xl h-full m-0 md:text-5xl lg:text-7xl ">
                   President
                </p>
                <p className=" text-orange-400 myfont  oxygen-light lg:text-5xl text-lg md:text-3xl p-2 font-sm ">
                  Rajendra Mahato
                </p>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </>
  );
}
