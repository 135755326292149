import React from 'react'
import banner from "../assets/banner.jpeg"
import banner2 from "../assets/banner2.jpeg"

export default function Banner() {
  return (
    <section className=''>
        <div className=''>
            <img  className="w-full h-screen hidden md:flex  object-fill " src={banner} alt="" />
            <img  className="w-full h-screen object-cover md:hidden sm:object-fill" src={banner2} alt="join party" />
        </div>
    </section>
  )
}
