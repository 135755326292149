import React from "react";

export default function Videos() {
  return (
    <div className="py-28 bg-gray-100">
      <div className="flex justify-center items-center sm:text-5xl  text-4xl pt-3 pb-10">
        <h1 className="text-green-600 flex justify-center items-center px-2 font-semibold">
          VIDEOS
        </h1>
        <span className="text-blue-800 font-semibold">GALLERY</span>
      </div>

      <div className="flex px-2 md:px-9 justify-center flex-grow items-center flex-wrap gap-10">
        <div class="max-w-sm  bg-white border border-gray-200 rounded-lg shadow d">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/27NUB19cUus?si=5Xq4fCIdXMi04fqN"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>

          <div class="p-5">
            <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
              लोसपा त्यागेका महतोले खोले नयाँ पार्टी नाम ‘राष्ट्रिय मुक्ति
              क्रान्ति’ | Global Report
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
