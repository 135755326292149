import React, { useState } from "react";
import { Carousel } from "flowbite-react";
import carousel from "../assets/carousel.jpg";
import carousel1 from "../assets/carousel1.jpg";
import carousel2 from "../assets/carousel2.jpg";
import { useUserStore } from "../hooks/useUserStore";
import RecentUploads from "./RecentUploads.jsx";
import Journey from "./Journey.jsx";
import News from "./News.jsx";
import PartyLeaders from "./PartyLeaders.jsx";
import TwoPointO from "./TwoPointO.jsx";
import Banner from "./Banner.jsx";
import axios from "axios";
import { useEffect } from "react";
import Presidents from "./Presidents.jsx";

function LandingPage() {
  let [Heading, setHeading] = useState(0);

  const user = useUserStore((state) => state.user);
  console.log({ user });

  const [photos, setPhotos] = useState(null);
  const fetchPhotos = async () => {
    try {
      const data = await axios.get(
        "https://api.rastriyamuktikranti.com/api/photo/get"
      );

      console.log({ data }, "123");

      setPhotos(data?.data?.photo);
    } catch (err) {
      console.log("Unable to fetch data");
      console.log(err);
    }
  };
  useEffect(() => {
    fetchPhotos();
  }, []);

  console.log({ photos });

  return (
    <section className=" bg-white pt-[10vh]">
      <Banner />

      <div className="h-[100vh] sm:h-[100vh] myfont   md:h-[100vh]  xl:h-[90vh] 2xl:h-[100vh] relative">
        <Carousel className="">
          {[carousel, carousel1, carousel2].map((src, index) => (
            <div className="relative w-full h-full" key={index}>
              <img
                src={src}
                className="w-full bg-slate-900 brightness-50 h-full object-cover"
              />
            </div>
          ))}
        </Carousel>
        <div className="absolute flex-col myfont flex left-0 lg:bottom-40 bottom-20 lg:h-20 md:h-10 h-5 gap-0 min-w-full bg-opacity-50 text-center">
          <p className="text-white myfont oxygen-bold text-2xl h-full m-0 md:text-5xl lg:text-7xl ">
            Party President
          </p>
          <p className=" text-orange-400 myfont  oxygen-light lg:text-5xl text-lg md:text-3xl p-2 font-sm ">
            Rajendra Mahato
          </p>
        </div>
      </div>
      <div className="bg-orange-500 py-7 myfont">
        <h1 className=" text-white lg:text-6xl text-xl md:text-4xl  flex justify-center">
          Recent Uploads
        </h1>
      </div>
      {/* Recent  Uploads */}
      <RecentUploads />
  
      {/* JOurney  */}
      <Journey />

      {/* Presidents  */}
      <Presidents/>

      {/* NEWS  */}
      <News />

      {/* Two point O  */}
      <TwoPointO />
    </section>
  );
}

export default LandingPage;
