import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../assets/imagesgallery/img1.jpeg";
import img2 from "../assets/imagesgallery/img2.jpeg";
import img3 from "../assets/imagesgallery/img3.jpeg";
import img4 from "../assets/imagesgallery/img4.jpeg";
import img5 from "../assets/imagesgallery/img5.jpeg";
import img6 from "../assets/imagesgallery/img6.jpeg";
import img7 from "../assets/imagesgallery/img7.jpeg";
import img8 from "../assets/imagesgallery/img8.jpeg";
import { useUserStore } from "../hooks/useUserStore";
import axios from "axios";
import { Carousel } from "flowbite-react";
import slider from "react-slick/lib/slider";

export default function RecentUploads() {
  const sliderRef = useRef(null); // Ref to control slider manually

  const user = useUserStore((state) => state.user);
  console.log({ user });

  const [photos, setPhotos] = useState(null);
  const fetchPhotos = async () => {
    try {
      const data = await axios.get(
        "https://api.rastriyamuktikranti.com/api/photo/get"
      );

      console.log({ data }, "123");

      setPhotos(data?.data?.photo);
    } catch (err) {
      console.log("Unable to fetch data");
      console.log(err);
    }
  };
  useEffect(() => {
    fetchPhotos();
  }, []);

  console.log({ photos });

  const settings = {
    infinite: true, // Infinite loop
    speed: 700, // Speed of transition
    slidesToShow: 4, // Show 4 slides per view
    slidesToScroll: 1, // Scroll 1 slide at a time
    responsive: [
      {
        breakpoint: 1024, // For medium screens (tablet size)
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600, // For small screens (mobile)
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For very small screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="h-[100vh] sm:h-[100vh] myfont  md:h-[100vh]  xl:h-[90vh] 2xl:h-[100vh] relative">

      {/* Carousel Slider */}
      <Carousel className="">
        {photos?.map((elem) => (
          <div key={elem?.photo_name} className="relative w-full h-full myfont bg-white px-1">
            <img
              src={elem?.photo_name}
              alt={elem?._id}
              className="w-full bg-slate-900 brightness-50 h-full object-cover"
            />
          </div>
        ))}
      </Carousel>

      {/* Manual Navigation Buttons */}
      {/* <button
        className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-2 opacity-60 rounded-full z-10"
        onClick={() => sliderRef.current.slickPrev()}
      >
        <svg
          className="h-12 w-12 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 8 8 12 12 16" />
          <line x1="16" y1="12" x2="8" y2="12" />
        </svg>
      </button>
      <button
        className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-transparent text-white  opacity-60  p-2 rounded-full z-10"
        onClick={() => sliderRef.current.slickNext()}
      >
        <svg
          className="h-12 w-12 text-white"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <polyline points="12 16 16 12 12 8" />
          <line x1="8" y1="12" x2="16" y2="12" />
        </svg>
      </button> */}
    </div>
  );
}
