import React from "react";
import SingleArticle from "../smallComponent/SingleArticle.jsx";
import img1 from "../assets/imagesgallery/img1.jpeg";
import img20 from "../assets/imagesgallery/img20.jpeg";
import img15 from "../assets/imagesgallery/img15.jpeg";
import img18 from "../assets/imagesgallery/img18.jpeg";

export default function Articles() {
  const article = [
    {
      img: img1,
      link: "https://kathmandupost.com/national/2024/09/17/rajendra-mahato-announces-new-party",
      head: "Rajendra Mahato announces new party",
      subhead:
        "Party to register at Election Commission in October; Mahato to serve as founding president.",
    },

    {
      img: img15,

      link: "https://www.ratopati.com/story/447696/party-announcement-by-rajendra-mahato",
      head: "राजनीति राजेन्द्र महतोद्वारा पार्टी घोषणा",
      subhead:
        "काठमाडौँ। राष्ट्रिय मुक्ति क्रान्तिका संयोजक राजेन्द्र महतोले मंगलबार विधिवतरुपमा पार्टी घोषणा गरेका छन् ।",
    },
    {
      img: img18,

      link: "http://english.ratopati.com/story/34615?npatro=1",
      head: "Mahato announces new party",
      subhead:
        "Kathmandu, September 17 — Rajendra Mahato, Coordinator of the Rashtriya Mukti Party Nepal (RMPN), officially announced the formation of his party on Tuesday.",
    },

    {
      img: img20,

      link: "https://www.nepalviews.com/2024/09/17/314376/",
      head: "राजेन्द्र महतोले गरे राष्ट्रिय मुक्ति क्रान्ति नेपाल पार्टीको घोषणा",
      subhead:
        "काठमाडौं । बहुलराष्ट्रिय राज्य स्थापनार्थ राष्ट्रिय मुक्ति क्रान्तिका संयोजक राजेन्द्र महतोले राष्ट्रिय मुक्ति क्रान्ति नेपाल पार्टीको घोषणा गरेका छन् ।",
    },
  ];
  return (
    <div className="bg-gray-100 py-28">
      <div className="flex justify-center items-center  pb-10">
        <h1 className="text-green-600 text-3xl lg:text-5xl sm:text-4xl font-semibold">
          ARTICLES
           {/* <span className="text-blue-800">GALLERY</span> */}
        </h1>
      </div>
      <div class="md:px-20 px-8 grid grid-cols-1 sm:grid-cols-2 justify-center md:grid-cols-3 gap-8">
        {article.map((elem) => {
          return (
            <SingleArticle
              img={elem.img}
              head={elem.head}
              subhead={elem.subhead}
              link={elem.link}
              key={elem}
            />
          );
        })}
      </div>
    </div>
  );
}
